import React from 'react'
import AthleteTopBar from './AthleteTopBar'
import Footer from './Footer'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap'
import TenantDropdown from '../components/TenantDropdown'

const AthleteLayout = ({tenant, children}) => {
  const history = useHistory();
  useEffect(() => {
    const isOnboarding = tenant.onboarding_completed === 0;
    if (isOnboarding) {
      history.push('/settings');
    }
  }, [tenant, history]);

  const isOnboardingCompleted = tenant.onboarding_completed === 1;

  return (
    <div className="athlete-view">
      <div id="layout-wrapper">
        {isOnboardingCompleted ? (
          <AthleteTopBar tenant={tenant} />
        ) : (
          <>
            <Alert color="primary" className="onboarding-alert">
              Onboarding: Koble til dine sosiale medier, så sender vi deg en e-post når kontoen din er klar til bruk av plattformen.
            </Alert>
            <div className="tenant-dropdown-container">
              <TenantDropdown variant="athlete" fullscreen={false} />
            </div>
          </>
        )}
        <div className="main-content">
          <div className="page-content">{children}</div>
        </div>
        <Footer />
        <ToastContainer 
          toastStyle={{ 
            backgroundColor: "var(--background-secondary)", 
            color: "var(--text-primary)"
          }} 
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  tenant: state.auth.tenant
})

export default connect(mapStateToProps, {})(AthleteLayout)