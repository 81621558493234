import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setDate } from "../store/actions/dateActions";
import Sidebar from "react-sidebar";
import { FaBars } from 'react-icons/fa';
import { AiFillHome, AiFillSetting, AiOutlineLogout } from 'react-icons/ai';
import { BsFillPersonFill, BsFillBarChartFill } from 'react-icons/bs';
import { RiDashboardFill, RiGroupLine } from 'react-icons/ri';
import foocusLogo from '../assets/images/foocusLogo.svg'
import TenantDropdown from "../components/TenantDropdown";
import { useTranslation } from "react-i18next";
import ThemeBtn from "../components/ThemeBtn";

const AthleteTopBar = ({
  isMenuOpened,
  toggleRightSidebar,
  openLeftMenuCallBack,
  tenant,
  menuOpen,
  date,
  setDate,
  client,
}) => {
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
    const { t } = useTranslation();

    const logout = () => {
        client.logout();
    }

    const getPageName = () => {
        switch (location.pathname) {
            case '/dashboard':
                return 'Profile';
            case '/value':
                return 'Value';
            case '/performance':
            case '/performance/source':
                return 'Performance';
            case '/audience':
                return 'Audience';
            case '/settings':
                return 'Settings';
            // Add more cases as needed
            default:
            return 'Home';
        }
    }

    const sidebarContent = (
        <div className="sidebar-content">
            <div className="logo-container">
                <img src={foocusLogo} alt="Foocus Logo" />
            </div>
          <ul>
            <li>
                <Link to="/dashboard">
                    <BsFillPersonFill /> {t('Profile')}
                </Link>
            </li>
            <hr />
            
            <li>
                <Link to="/performance">
                    <RiDashboardFill /> {t('Performance')}
                </Link>        
            </li>
            <hr />

            <li>
                <Link to="/value">
                    <BsFillBarChartFill /> {t('Value')}
                </Link>
            </li>
            <hr />

            <li>
                <Link to="/audience">
                    <RiGroupLine /> {t('Audience')}
                </Link>
            </li>
            <hr />

            <li>
                <Link to="/reports">
                    <AiFillHome /> {t('Reports')}
                </Link>
            </li>
            <hr />

            <li>
                <Link to="/settings">
                    <AiFillSetting /> {t('Settings')}
                </Link>
            </li>
            <hr />

            <li>
                <Link to="#" onClick={() => logout()}>
                    <AiOutlineLogout /> {t('Logout')}
                </Link>
             </li>
          </ul>
        </div>
      );

    return (
        <header id="page-topbar" className="athlete-topbar">
            
            <Sidebar
                sidebar={sidebarContent}
                open={sidebarOpen}
                onSetOpen={setSidebarOpen}
                styles={{ sidebar: { width: '250px', height: '100vh', position: 'fixed', zIndex: 4} }}
            >
                <div></div>
            </Sidebar>
            
            <div className="navbar-header">
                <div className="navbar-left">
                    <button 
                        onClick={toggleSidebar} 
                        className="sidebar-toggle"
                    >
                        <FaBars />
                    </button>
                </div>
                <div className="navbar-brand-box">
                    <h3>{t(getPageName())}</h3>
                </div>
                <div className="navbar-right">
                    {/* <LanguageSelect /> */}
                    <ThemeBtn />
                    <TenantDropdown variant="athlete" fullscreen={false} />
                </div>
            </div>
        </header>
    );
};

const mapStateToProps = (state) => ({
    tenant: state.auth.tenant,
    isFullscreenEnabled: state.isFullscreenEnabled,
    date: state.date,
    client: state.auth.client,
});

export default connect(mapStateToProps, { setDate })(AthleteTopBar);
