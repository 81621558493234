import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from "reactstrap";
import { connect } from "react-redux";
import BoxedMetric from "../components/BoxedMetric";
import SocialMediaMetric from "../components/SocialMediaMetric";
import PersonOverview from "../components/PersonOverview";
import SocialTimeline from "../components/SocialTimeline.jsx";
import numeral from "numeral";
import usePersonSocialMetrics from "../query/hooks/usePersonSocialMetrics";
import useTenantProfileData from "../query/hooks/useTenantProfileData";
import usePersonSocialTimeline from "../query/hooks/usePersonSocialTimeline";
import { useTranslation } from "react-i18next";

const DashboardPerson = ({ selectedCurrency, tenant }) => {
  const {data: profile, isLoading: isLoadingProfile} = useTenantProfileData();
  const {data: socialMetrics, isLoading: isLoadingMetrics} = usePersonSocialMetrics();
  const {data: socialTimelineItems, isLoading: isLoadingTimeline} = usePersonSocialTimeline();
  const { t } = useTranslation();
  const [timelineItems, setTimelineItems] = useState([]);

  const primarySocialFn = (primary) => {
    return `${numeral(primary).format('0,0a')}`;
  }

  const currencyFn = (primary) => {
    return `${numeral(primary).format('0.0a')} NOK`;
  }

  const postsFn = (primary) => {
    return `${numeral(primary).format('0,0')}`;
  }

  useEffect(() => {
    if (!isLoadingTimeline && socialTimelineItems && socialTimelineItems.length > 0) {
      setTimelineItems(socialTimelineItems);
    }
  }, [isLoadingTimeline, socialTimelineItems]);

  return (
    <div className="container-fluid pt-4 p-0 dashboard-person">
      {isLoadingProfile ? (
        <div className="profile-section">
          <div className="d-flex justify-content-center">
            <div className="loader-circle">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PersonOverview
          image={profile?.imageUrl}
          name={profile?.name}
          title={profile?.shortDescription}
        />
      )}

      <Row className="mb-4 px-4">
        <Col xs={4}>
          <BoxedMetric metric="commerical-value" colour="lightgrey" titleFn={currencyFn} secondaryFn={() => t('Commerical value')} />
        </Col>
        <Col xs={4}>
          <BoxedMetric metric="total-followers" colour="lightgrey" titleFn={primarySocialFn} secondaryFn={() => t('Followers')} />
        </Col>
        <Col xs={4}>
          <BoxedMetric metric="posts-count" colour="lightgrey" titleFn={postsFn} secondaryFn={() => t('Posts')} />
        </Col>
      </Row>

      <Row className="mb-4 px-4">
        {isLoadingMetrics && <Spinner color="primary" />}
        {socialMetrics && socialMetrics.map((metric) => (
          <Col xs={12} md={6} lg={4} key={metric.source} className="mb-4">
            <div className="metric-card">
              <SocialMediaMetric 
                source={metric.source} 
                description={t('Followers')} 
                value={numeral(metric.follower_count).format('0,0')} 
                growth={numeral(metric.growth).format('+ 0.00%')} 
              />
            </div>
          </Col>
        ))}
      </Row>   
      
      <Row className="mb-4 px-4">
        <Col xs={12}>
          <h3 className="section-title">{t('Social Timeline')}</h3>
        </Col>
      </Row>    
      
      {!isLoadingTimeline && timelineItems !== undefined && (
        <div className="timeline-section">
          <SocialTimeline items={timelineItems} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  selectedCurrency: state.currency.selectedCurrency,
	tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(DashboardPerson);
