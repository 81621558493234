import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'


/* Components */
import DemographicsChart from '../components/DemographicsChart'
import TicketPotential from '../components/TicketPotential'
import OverallMetric from '../components/OverallMetric'
import InfoTooltip from '../components/InfoTooltip'
import PerformingSocialMediaPosts from '../components/PerformingSocialMediaPosts'
import BestSocialMediaPostBubbles from '../components/BestSocialMediaPostBubbles'

const FansToppserien = (props) => {
  const { t } = useTranslation();
  const {name} = props.user
  console.log(name)


  return (
    <>
      <div className="container-fluid pt-4 mt-3">
        <Row className="mb-4">
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric followCalender metric="engagement-this-year" tooltip={t('Viser totalt engasjement som er skapt hittil i år, inklusive antall likes, kommentarer og delinger i sosiale kanaler, samt nettsidebesøk med mer enn to sidevisninger og antall personer som har sett hele kamper på TV. Prosent-tallet viser trendutvikling sammenlignet med forrige måned.')} />
          </Col>
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric metric="visibility" tooltip={t('Viser totalt antall visninger (impressions) som er skapt hittil i år på sosiale medier, nettsider og TV (antall som har sett minimum 1 minutt av kampene). Prosent-tallet viser trendutvikling sammenlignet med forrige måned')} />
          </Col>
          <Col xl={3} md={6}>
            <OverallMetric metric="visibility-value" tooltip={t('Viser total verdi av alle visninger (impresssions) hittil i år, kalkulert med standard mediepriser for sosiale medier, nettsider og TV. Prosent-tallet viser trendutvikling sammenlignet med forrige måned.')} />
          </Col>
          <Col xl={3} md={6}>
            <OverallMetric metric="tickets" tooltip={t('Viser totalt antall enkeltbilletter som er solgt hittill denne sesongen. Prosent-tallet viser trendutvikling sammenlignet med forrige måned.')} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">{t('Alder og kjønn')}
                  <InfoTooltip tooltip={t('Viser demografisk nedbrytning av følgere i forhold til alder/livsfase og kjønn.')} />
                </h4>
                <DemographicsChart />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">{t('PUBLIKUMSPOTENSIAL PER MOTSTANDER')}
                  <InfoTooltip tooltip={t('Viser snitt publikum for hjemme-oppgjørene mot hver motstander de X siste sesongene. Gir indikasjoner på hvilke oppgjør som selger godt og mindre godt.')} />
                </h4>
                <div style={{height: '40px'}} className="my-4"></div>
                <TicketPotential />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={12}>
            <BestSocialMediaPostBubbles />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={12}>
            <PerformingSocialMediaPosts test={test} type="BESTE" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={12}>
            <PerformingSocialMediaPosts test={test} type="SVAKESTE" />
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.auth.user,
})

export default connect(mapStateToProps)(FansToppserien)