import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Nav, NavItem, NavLink, Spinner } from "reactstrap";
import classnames from "classnames";
import ApiService from "../services/ApiService";
import { connect } from "react-redux";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "../utils/useTheme";

const StationDividedValueChart = ({ date, selectedCurrency, tenant, selectedTenantId, partnerId }) => {
  const [activeTab, setActiveTab] = useState(0);
  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }
  var darkTheme = true
  // check if light or dark mode
  if (useTheme() === "light-mode"){
    darkTheme = false
  }
  const {
    data: sources,
    isLoading,
    isRefetching,
    isSuccess,
  } = useQuery({
    queryKey: ["partnerValuePerStation", tenant.id, partnerId, selectedTenantId, startDate, endDate],
    queryFn: () => ApiService.getPartnerValuePerStation(partnerId, selectedTenantId, since, until),
    select: (data) => data.data.sources,
    enabled,
    placeholderData: { data: { sources: [] } },
  });

  const chartOptions = (source) => {
    var labelcolor = '#505050'
    if (darkTheme === true) {
      labelcolor = '#fff'
    } 
    return {
credits: { enabled: false },
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: source.data.yTitle,
        },
        labels: {
          style: {
            color: labelcolor
          }
        },
      },
      xAxis: {
        categories: source.data.labels,
        title: {
          text: source.data.xTitle,
        },
        labels: {
          style: {
            color: labelcolor
          }
        },
      },
      series: source.data.series.map((serie) => ({
        ...serie,
        data: serie.data.map((serieValue) =>
          Math.floor(serieValue * selectedCurrency.value)
        ),
      })),
      plotOptions: {
        column: {
          stacking: "normal",
          borderColor: null,
        },
      },
    };
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const renderTab = (graph, index) => {
    return (
      <NavItem key={index}>
        <NavLink
          href="#"
          className={classnames({ active: activeTab === index })}
          onClick={() => toggleTab(index)}
        >
          {graph.source}
        </NavLink>
      </NavItem>
    );
  };

  const renderSource = (graph, index) => {
    const chart = JSON.parse(JSON.stringify(chartOptions(graph)));
    return <HighchartsReact highcharts={Highcharts} options={chart} />;
  };

  return (
    <>
      {(isLoading || isRefetching) && <Spinner color="primary" />}
      {isSuccess && (
        <>
          <Nav pills className="navtab-bg my-4 center-pills">
            {sources.map((source, index) => renderTab(source, index))}
          </Nav>
          {sources[activeTab] && renderSource(sources[activeTab])}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  selectedCurrency: state.currency.selectedCurrency,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(StationDividedValueChart);
