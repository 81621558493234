import React, { useState } from 'react'
import { Nav, NavItem, NavLink, Spinner } from 'reactstrap'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* Components */
import useGetSponsorObjects from '../query/hooks/useGetSponsorObjects'

const SponsorObjectTabs = ({onClickTab}) => {
  const [activeTab, setActiveTab] = useState(0)
  const {data: sponsorObjects, isFetching} = useGetSponsorObjects();

  const { t } = useTranslation()

  function toggleTab(tabIndex, sponsorObjectId) {
    if (activeTab !== tabIndex) {
      setActiveTab(tabIndex)
      onClickTab(tabIndex, sponsorObjectId)
    }
  }

  function renderTab(tab, index) {
    return (
      <NavItem key={index+1}>
        <NavLink
          href="#"
          className={classnames({
            active: activeTab === index+1
          })}
          onClick={() => {
            toggleTab(index+1, tab.id)
          }}
        >
          {tab.name}
        </NavLink>
      </NavItem>
    );
  };


  return (
    <div className="flex align-items-center">
      {isFetching && (
        <div className="mr-4">
          <Spinner />
        </div>
      )}
      {
        sponsorObjects.length > 0 && (
          <Nav tabs className="nav-tabs-custom mb-4 mt-4 flex-fill">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === 0
                })}
                onClick={() => {
                  toggleTab(0, 0)
                }}
              >
                <span className="d-none d-sm-block">{t('Portfolio')}</span>
              </NavLink>
            </NavItem>
            {sponsorObjects.map((tab, index) => renderTab(tab, index))}
            {/* <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === (sponsorObjects.length + 1)
                })}
                onClick={() => {
                  toggleTab(sponsorObjects.length + 1, null)
                }}
              >
                <span className="d-none d-sm-block"><i className="mdi mdi-plus-box-multiple-outline"></i>{" "}{t('Add source')}</span>
              </NavLink>
            </NavItem> */}
          </Nav>
        )
      }
    </div>
  )
}


SponsorObjectTabs.propTypes = {
    onClickTab: PropTypes.func.isRequired
}

export default SponsorObjectTabs