import { Card, CardBody, Spinner } from "reactstrap";

const ValueSponsorMetric = ({sponsor, metric, logo, value, growth, loading}) => {
  
  const growthClass = parseFloat(growth) >= 0 ? 'positive' : 'negative';

  return (
    <Card className="value-sponsor-metric">
      <CardBody>
        <div className="metric-content">
          <div className="sponsor-info">
            { loading ? <Spinner color="primary" /> : 
              <img src={logo} alt={sponsor} className="sponsor-logo" />
            }
            <div>
              <p className="sponsor-name">
                { sponsor !== undefined && sponsor}
              </p>
              <p className="sponsor-metric">{metric}</p>
            </div>
          </div>
          <div className="metric-values">
            { loading ? <Spinner color="primary" /> : 
              <>
                <p className="metric-value">{value}</p>
                <p className={`metric-growth ${growthClass}`}>{Math.round(growth * 100)}%</p>
              </>
            }
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default ValueSponsorMetric;
