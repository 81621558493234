import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
// Custom Scrollbar

/* Components */
import OverallMetric from '../components/OverallMetric'
import TicketPotential from '../components/TicketPotential'
import StadiumSellOut from '../components/StadiumSellOut'
import TicketsSold from '../components/TicketsSold'
import { useTranslation } from 'react-i18next'


const Arena = () => {
	const { t } = useTranslation();
	return (
		<>
			<div className="container-fluid pt-4 mt-3">
				<Row className="mb-4">
					<Col xl={3} md={6} className="mb-4 mb-xl-0">
						<OverallMetric
							followCalender
							noTrend
							metric="seasontickets"
							tooltip={t('Viser totalt antall sesongkort som er solgt hittil i sesongen. Prosent-tallet viser trendutvikling sammenlignet med forrige måned.')}
						/>
					</Col>
					<Col xl={3} md={6} className="mb-4 mb-xl-0">
						<OverallMetric
							followCalender
							noTrend
							metric="tickets"
							tooltip={t('Viser totalt antall enkeltbilletter som er solgt hittil i sesongen. Prosent-tallet viser trendutvikling sammenlignet med forrige måned.')}
						/>
					</Col>
					<Col xl={3} md={6}>
						<OverallMetric
							followCalender
							noTrend
							metric="audience"
							tooltip={t('Antall registrerte tilskuere på kampene som er spilt så langt.')}
						/>
					</Col>
					<Col xl={3} md={6}>
						<OverallMetric
							followCalender
							noTrend
							metric="average-attendance"
							tooltip={t('Gjennomsnittlig antall tilskuere per kamp for kampene som er spilt så langt.')}
						/>
					</Col>
				</Row>
				<Row className="mb-4">
					<Col sm={12}>
						<Card className="mini-stat bg-card text-white">
							<CardBody>
								<StadiumSellOut />
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row className="mb-4">
					<Col sm={12} md={6}>
						<Card className="mini-stat bg-card text-white">
							<CardBody>
								<h4 className="card-title mb-4">
									{t('Publikumspotensial per motstander')}
								</h4>
								<TicketPotential />
							</CardBody>
						</Card>
					</Col>
					<Col sm={12} md={6}>
						<Card className="mini-stat bg-card text-white">
							<CardBody>
								<h4 className="card-title mb-4">{t('Billettsalg per oppgjør')}</h4>
								<TicketsSold />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default Arena
