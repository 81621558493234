import { Col, Row, Spinner } from "reactstrap";
import useSponsorsValue from "../query/hooks/useSponsorsValue";
import ValueSponsorMetric from "./ValueSponsorMetric";
import numeral from "numeral";


const AthleteSponsorValue = () => {
  const {data, isLoading, isRefetching} = useSponsorsValue();

  return (
    <>
      {(isLoading || isRefetching) && (
        <Spinner color="primary" />
      )}
    <Row className="px-4">
      {data?.map((sponsor) => (
        <Col xs={12} sm={6} md={6} lg={4} className="mb-3">
          <ValueSponsorMetric key={`sponsor-${sponsor.id}`} loading={isRefetching} sponsor={sponsor.name} logo={sponsor.logoUrl} metric="Sponsor" value={numeral(sponsor.value).format('0.0a')} growth={sponsor.growth}  />
        </Col>
      ))}
    </Row>
    </>

  )
};

export default AthleteSponsorValue;
