import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsReact from "highcharts-react-official";
import {
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import ApiService from "../services/ApiService";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";

HighchartsExporting(Highcharts);


const DemographicsChart = ({ date, tenant, type, overrideTenantId }) => {
  const { t } = useTranslation();
  const [sourcesPercentage, setSourcesPercentage] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [mode, setMode] = useState("ant");

  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;

  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }
  HighchartsExporting(Highcharts);

  const {
    data: sources,
    isLoading,
    isRefetching,
    isSuccess,
  } = useQuery({
    queryKey: [
      "DemographicsChart",
      type,
      tenant.id,
      overrideTenantId,
      startDate,
      endDate,
    ],
    queryFn: () =>
      type === "league"
        ? ApiService.getDemographicsByLeague(overrideTenantId, since, until)
        : ApiService.getDemographics(since, until),
    select: (data) => data.data.sources,
    onSuccess: (data) => {
      setSourcesPercentage(
        data.map((source) => {
          let total = 0;
          source.data.series.forEach((dataSource) => {
            dataSource.data.forEach((dataSerie) => {
              total += dataSerie;
            });
          });
          return {
            ...source,
            data: {
              ...source.data,
              series: source.data.series.map((dataSource) => {
                return {
                  ...dataSource,
                  data: dataSource.data.map((dataSerie) =>
                    Math.round((dataSerie / total) * 100)
                  ),
                };
              }),
            },
          };
        })
      );
    },
    enabled,
    placeholderData: { data: { graphs: [] } },
  });

  const chartOptions = (source) => {
    const chartStyles = getComputedStyle(document.documentElement);
    const backgroundColor = chartStyles.getPropertyValue('--chart-background').trim();
    const labelColor = chartStyles.getPropertyValue('--chart-text').trim();
    const gridColor = chartStyles.getPropertyValue('--chart-grid').trim();
    
    return {
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
          }
        }
      },
      credits: { enabled: false },
      chart: {
        type: "column",
        backgroundColor: backgroundColor,
        height: 403,
        style: {
          fontFamily: 'var(--font-family-base)'
        }
      },
      title: {
        text: "",
      },
      yAxis:
        mode === "%"
          ? {
              min: 0,
              tickInterval: 10,
              labels: {
                format: "{value}%",
                style: {
                  color: labelColor
                }
              },
              gridLineColor: gridColor,
              colorAxis: {
                maxColor: "var(--accent-color)",
                minColor: "var(--background-lighter)",
                min: 0,
                max: 10,
                labels: {
                  format: "{value}%",
                  style: {
                    color: labelColor
                  }
                },
              },
              plotBands: [
                {
                  backgroundColor: backgroundColor,
                  color: "var(--background-lighter)",
                  from: 60,
                  to: 80,
                  innerRadius: "50%",
                },
              ],
            }
          : {
              tickInterval: null,
              min: null,
              max: null,
              title: {
                text: "",
              },
              labels: {
                format: "{value}",
                style: {
                  color: labelColor
                }
              },
              gridLineColor: gridColor,
            },
      xAxis: {
        categories: source.data.labels,
        labels: {
          style: {
            color: labelColor
          }
        },
        title: {
          text: source.data.xTitle,
          style: {
            color: labelColor
          }
        },
        gridLineColor: gridColor,
      },
      series: source.data.series.map((serie) => ({
        ...serie,
        tooltip: {
          valueSuffix: mode === "%" ? "%" : "",
        },
      })),
      plotOptions: {
        column: {
          stacking: "normal",
        },
        series: {
          borderColor: null,
          colors: [
            'var(--accent-color)',
            'var(--background-lighter)'
          ]
        }
      },
    };
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const renderTab = (source, index) => {
    return (
      <NavItem key={index}>
        <NavLink
          href="#"
          className={classnames({
            active: activeTab === index,
          })}
          onClick={() => {
            toggleTab(index);
          }}
        >
          {source.source}
        </NavLink>
      </NavItem>
    );
  };

  let src = mode === "ant" ? sources : sourcesPercentage;

  const activeSource = src?.[activeTab];

  return (
    <div className="demographics-chart">
      {(isLoading || isRefetching) && (
        <div className="d-flex justify-content-center">
          <Spinner color="primary" />
        </div>
      )}
      {activeSource && isSuccess && (
        <>
          <Nav pills className="navtab-bg center-pills">
            {src.map((source, index) => renderTab(source, index))}
          </Nav>
          <div className="chart-container">
            <HighchartsReact
              highcharts={Highcharts}
              options={JSON.parse(JSON.stringify(chartOptions(activeSource)))}
            />
          </div>
        </>
      )}
      <Nav pills className="navtab-bg center-pills">
        <NavItem className="percent-button">
          <NavLink
            onClick={() => setMode("%")}
            className="percent-link"
            active={mode === "%"}
          >
            %
          </NavLink>
          <NavLink
            onClick={() => {
              setMode("ant");
            }}
            className="percent-link"
            active={mode === "ant"}
          >
            {t("ant")}
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(DemographicsChart);
