import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'


/* Components */
import VisibilityGraph from '../components/VisibilityGraph'
import EngagementGraph from '../components/EngagementGraph'
import OverallMetric from '../components/OverallMetric'
import TvViewers from '../components/TvViewers'
import DemographicsChart from '../components/DemographicsChart'
import InfoTooltip from '../components/InfoTooltip'
import PerformingSocialMediaPosts from '../components/PerformingSocialMediaPosts'
import LineGraph from "../components/LineGraph";
import { useEngagementChannels } from "../query/hooks";

const DashboardToppserien = (props) => {
  const {t} = useTranslation();

    return (
      <>
        <div className="container-fluid pt-4 mt-3">
          <Row className="mb-4">
            <Col xl={3} md={6} className="mb-4 mb-xl-0">
              <OverallMetric
                metric="engagement-this-year"
                tooltip={t(
                  "Viser totalt engasjement som er skapt hittil i år, inklusive antall likes, kommentarer og delinger i sosiale kanaler, samt nettsidebesøk med mer enn to sidevisninger og antall personer som har sett hele kamper på TV. Prosent-tallet viser trendutvikling sammenlignet med forrige måned."
                )}
              />
            </Col>
            <Col xl={3} md={6} className="mb-4 mb-xl-0">
              <OverallMetric
                metric="visibility"
                tooltip={t(
                  "Viser totalt antall visninger (impressions) som er skapt hittil i år på sosiale medier, nettsider og TV (antall som har sett minimum 1 minutt av kampene). Prosent-tallet viser trendutvikling sammenlignet med forrige måned"
                )}
              />
            </Col>
            <Col xl={3} md={6}>
              <OverallMetric
                metric="visibility-value"
                tooltip={t(
                  "Viser total verdi av alle visninger (impresssions) hittil i år, kalkulert med standard mediepriser for sosiale medier, nettsider og TV. Prosent-tallet viser trendutvikling sammenlignet med forrige måned."
                )}
              />
            </Col>
            <Col xl={3} md={6}>
              <OverallMetric
                metric="followers"
                tooltip={t("Viser totalt antall følgere i sosiale medier")}
              />
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={12} md={6}>
              <Card className="mini-stat bg-card text-white">
                <CardBody>
                  <h4 className="card-title mb-4">
                    {t("Alder og kjønn")}
                    <InfoTooltip
                      tooltip={t(
                        "Viser demografisk nedbrytning av følgere i forhold til alder/livsfase og kjønn."
                      )}
                    />
                  </h4>
                  <DemographicsChart />
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={6}>
              <Card className="mini-stat bg-card text-white">
                <CardBody>
                  <h4 className="card-title mb-4">
                    {t("Engasjement kanaler")}
                    <InfoTooltip
                      tooltip={t(
                        "Viser totalt antall følgere, engasjement (likes, delinger, kommentarer etc) og synlighet (visninger) som er skapt via klubbens kanaler, målt opp mot gjennomsnittet i ligaen."
                      )}
                    />
                  </h4>
                  <LineGraph
                    useGraphQuery={useEngagementChannels}
                    defaultActiveTab={0}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={12} md={6}>
              <Card className="mini-stat bg-card text-white">
                <CardBody>
                  <h4 className="card-title mb-4">
                    {t("Engasjement")}
                    <InfoTooltip
                      tooltip={t(
                        "Viser utviklingen i engasjement (likes, delinger, kommentarer etc) per kanal måned for måned og akkumulert hittil i år."
                      )}
                    />
                  </h4>
                  <EngagementGraph />
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={6}>
              <Card className="mini-stat bg-card text-white">
                <CardBody>
                  <h4 className="card-title mb-4">
                    {t("Synlighet")}
                    <InfoTooltip
                      tooltip={t(
                        "Viser utviklingen i synlighet (visninger/impressions) per kanal måned for måned og akkumulert hittil i år."
                      )}
                    />
                  </h4>
                  <VisibilityGraph />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={12} md={12}>
              <Card className="mini-stat bg-card text-white">
                <CardBody>
                  <h4 className="card-title mb-4">
                    {t("TV-seere per oppgjør")}
                  </h4>
                  <TvViewers />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12}>
              <PerformingSocialMediaPosts type="BESTE" />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12}>
              <PerformingSocialMediaPosts type="SVAKESTE" />
            </Col>
          </Row>
        </div>
      </>
    );
  }

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(DashboardToppserien)