import React, { useEffect, useState , useCallback } from 'react'
import { Col, Row, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from 'reactstrap'
import axios from 'axios'
import Switch from "react-switch";
import { Globals } from '../Globals'
import SocialService from '../services/SocialService';

const DataSource = ({account, ...rest}) => {
  console.log("account", account)
  const [loading, setLoading] = useState(false);
  //const [account, setAccount] = useState(null);
  const [sources, setSources] = useState([]);
  const [settingMenu, setSettingMenu] = useState(false);

  const fetchSource = useCallback(async () => {
      if (!account) return;

      try {
        setLoading(true);
        const res = await axios.get(`${Globals.API_URL}/api/data-sources/${account.id}`)
        setSources(res.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      } 
  }, [account]);

useEffect(() => {
  fetchSource();
}, [fetchSource]);


  const updateSelect = async (itemSource, include) => {
    console.log("account", account)
    console.log("itemSource", itemSource)
    console.log("include", include)

    setLoading(true);
    if (itemSource.type === 'instagram') {
      await axios.post(`${Globals.API_URL}/api/data-sources/${account.id}/${itemSource.type}/${itemSource.id}`, { include });
    } else {
      await axios.post(`${Globals.API_URL}/api/data-sources/${account.id}/${account.provider}/${itemSource.id}`, { include });
    }
    fetchSource();
  }

  const deleteSource = () => {
    axios.delete(`${Globals.API_URL}/api/data-sources/delete-account/${account.id}/`)
    window.location.reload(false);
  }

  const normalizeSource = (source) => {
    console.log(source);
    switch (account.provider) {
      case 'facebook':
      case 'facebook-business':
        let username = source?.name ?? source.username;
        if (source.type === 'instagram') {
          username = `@${username}`;
        }
        return {
          name: username,
          sub: source.account_id
        }
      case 'google':
        return {
          name: source.website_url,
          sub: source.ga_account_id,
        }
      case 'twitter':
        return {
          name: source.nickname,
          sub: source.twitter_id,
        }
      case 'fathom':
        return {
          name: "name",
          sub: "id",
        }
      default:
        return {
          name: '',
          sub: '',
        }
    }
  }

  const getExpiresDays = (expires_at) => {
    const now = new Date().getTime() / 1000;
    const diffTime = expires_at - now;
    const diffDays = Math.ceil(diffTime / (60 * 60 * 24));
    return diffDays;
  }

  const getExpiresText = (expires_at) => {
    const days = getExpiresDays(expires_at);
    if (days <= 0) {
      return 'Utløpt';
    } else {
      return 'Utløper om ' + days + ' dager';
    }
  }

  const getExpiresBadgeClass = (expires_at) => {
    const days = getExpiresDays(expires_at);
    if (days <= 0) {
      return 'badge-danger';
    } else if (days <= 7) {
      return 'badge-warning';
    } else {
      return 'badge-success';
    }
  }

  const renderSource = (source) => {
    const isChecked = source.include === 1;
    console.log("source", source)
    const normalizedSource = normalizeSource(source);
    return (

      <Col sm={6} key={source.id}>
        <Card className="bg-card-box mb-4 card-on-card">
            <CardBody>
              <h6>{normalizedSource?.name}</h6>
              <p className="card-title-desc mb-1">{normalizedSource?.sub}</p>
              <Switch
                className="mr-2"
                uncheckedIcon={
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                  }}
                  >
                    {" "}
                    Nei
                  </div>
                }
                checkedIcon={
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingLeft: 2
                    }}
                  >
                    {" "}
                    Ja
                  </div>
                }
                onColor="#02a499"
                onChange={() =>
                  updateSelect(source, !isChecked)
                }
                checked={isChecked}
              />
                Inkluder data
            </CardBody>
        </Card>
      </Col>
    );
  }

  const getSocialLink = () => {
    switch (account.provider) {
      case 'facebook':
        return SocialService.facebookLoginLink();
      case 'facebook-business':
        return SocialService.facebookBusinessLoginLink();
      case 'google':
        return SocialService.googleLoginLink();
      case 'twitter':
        return SocialService.twitterLoginLink();
      default:
        return '/'
    }
  }

  

  const renderSources = () => {
    if (!Array.isArray(sources)) {
      // Handle the case where sources is not an array
      console.error('Expected sources to be an array, but got:', sources);
      return <Col><p>Tom</p></Col>;
    }

    return sources.map((source, index) => renderSource(source, index));
  };
  if (!account) {
    return <></>;
  }
  // console.log("sources", sources)
  // console.log("account", account)
  var expires = account.expires_at;
  var isFacebook = (account.provider === 'facebook' || account.provider === 'facebook-business') ? true : false;
  console.log('expires', expires);
  console.log('isFacebook', isFacebook);
  console.log('account', account);
  return (
    <>
      <Row>
        <Col sm={6}>
          <h4 className="text-capitalize">{account.provider}</h4>
          <p className="text-muted">{account.username}</p>
        </Col>
        <Col sm={6}>
          <div className="flex float-right">
            
            { expires && isFacebook && account.status !== undefined && account.status !== 'active' && (
              <Badge className={`small-badge ${getExpiresBadgeClass(expires)}`}>
                {getExpiresText(expires)}
              </Badge>
            )}
            <Dropdown isOpen={settingMenu} toggle={() => setSettingMenu(prev => !prev)}>
              <DropdownToggle color="primary" className="btn btn-primary dropdown-toggle waves-effect waves-light">
                <i className="mdi mdi-settings mr-2"></i> Valg
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag="a" href={getSocialLink()}>Oppdater</DropdownItem>
                <DropdownItem divider />
                <DropdownItem tag="a" onClick={deleteSource}>Slett</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          </Col>
      </Row>
      <Row>
      {loading && <div className="spinner-border text-primary m-1" role="status"><span className="sr-only">Loading...</span></div>}
        {sources ? (sources.length <= 0 && !loading) && <Col><p className="text-center">Det kan ta opp mot et minutt å laste kontoinnhold</p></Col> : <Col><p>Tom</p></Col>}
        {!loading && renderSources()}
      </Row>
    </>
  )
}

export default DataSource