import React, { useState } from "react"
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
import { Nav, NavItem, NavLink, Spinner, Card, CardBody } from 'reactstrap'
import classnames from 'classnames'
import { useThemeContext } from "../contexts/ThemeContext";

const LineGraphAthlete = ({
  title,
  subtitle,
  useGraphQuery,
  queryParams = [],
  defaultActiveTab = 0,
}) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const { data, isLoading, isRefetching, isSuccess } = useGraphQuery(
    ...queryParams
  );
  const { isDark } = useThemeContext();

  const chartOptions = () => {
    const graph = data?.graphs[activeTab];

    if (!graph) {
      return;
    }
    let backgroundColor = isDark ? "#fff" : "#2B283B";
    let labelcolor = isDark ? "#fff" : "#000"

    return {
      credits: { enabled: false },
      exporting: { enabled: false },
      chart: {
        type: "spline",
        backgroundColor
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: graph.data.yTitle,
          style: {
            color: labelcolor
          }
        },
        labels: {
          style: {
            color: labelcolor
         }
        }
      },
      xAxis: {
        categories: graph.data.labels,
        labels: {
          rotation: 0,
          style: {
            color: labelcolor
         }
        },
      },
      series: graph.data.series,
    };
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const renderTab = (graph, index) => {
    return (
      <NavItem key={index}>
        <NavLink
          href="#"
          className={classnames({ active: activeTab === index })}
          onClick={() => toggleTab(index)}
        >
          {graph.source}
        </NavLink>
      </NavItem>
    );
  };

  if (isLoading || isRefetching) {
    return (
      <Spinner
        color="primary"
        size="sm"
        style={{ position: "absolute", right: 20 }}
      />
    );
  }

  return (
    <>
      {isSuccess && (
        <>
          <Nav tabs style={{border: 'none'}}>
            {Array.isArray(data?.graphs) && data.graphs.map((graph, index) => renderTab(graph, index))}
          </Nav>
          <Card style={{borderRadius: 0}}>
            <CardBody>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <p className="card-title-athlete mb-0">{title}</p>
                <p className="card-title-athlete mb-0">{data.total}</p>
              </div>
              <p style={{margin: 0}}>{subtitle}</p>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions()}
              />
            </CardBody>
          </Card>
        </>
      )}
    </>
  );
};

export default LineGraphAthlete;
