import ReactSelect from "react-select";
import useAllBrands from "../query/hooks/useAllBrands";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import useEnableSponsors from "../query/hooks/useEnableSponsors";
import { useThemeContext } from "../contexts/ThemeContext";

const AddSponsors = () => {
	const [selectedBrands, setSelectedBrands] = useState([]);
	const {data: brands, isLoading: isLoadingBrands, isRefetching: isRefetchingBrands} = useAllBrands();
	const { isDark } = useThemeContext();

	const {mutate, isLoading} = useEnableSponsors();
	const {t} = useTranslation();

	useEffect(() => {
		setSelectedBrands(brands.filter(brand => brand.connected).map(brand => ({
			value: brand.id,
			label: brand.name
		})));
	}, [brands]);

	const onSubmit = () => {
		const selectedBrandIds = selectedBrands.map(brand => brand.value);
		mutate(selectedBrandIds);
	}

	return (
		<div className="add-sponsors-container">
			<span>{t('Sponsors')} {(isLoadingBrands || isRefetchingBrands) && <Spinner className="ml-2" size="sm" color="primary" />}</span>
			<div className="mt-2 mb-4">
				{isLoadingBrands && <Spinner color="primary" />}
				<ReactSelect
					className={`react-select__container mb-2 ${isDark ? 'theme-dark' : 'theme-light'}`}
					classNamePrefix="react-select"
					menuPortalTarget={document.body}
					isMulti
					value={selectedBrands}
					options={brands.map(brand => (
						{value: brand.id, label: brand.name}
					))}
					onChange={setSelectedBrands}
				/>
				<Button onClick={onSubmit} disabled={isLoading} color="primary">
					{t('Save')}
					{isLoading && <Spinner className="ml-2" size="sm" />}
					</Button>
			</div>
			<p>Finner du ikke en sponsor? Gi beskjed til <a href="mailto:kim@foocus.ai">kim@foocus.ai</a></p>
		</div>
	)
}

export default AddSponsors;