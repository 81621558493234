import { FaRegEdit } from "react-icons/fa";

const PersonOverview = ({name, title, image}) => {
  return (
    <div className="person-overview mb-4">
      <div className="profile-container mb-2">
        <div className="profile-wrapper">
          <img
            src={image}
            alt=""
            className="profile-image"
          />
          <div className="edit-button">
            <FaRegEdit className="edit-icon" />
          </div>
        </div>
      </div>
      <p className="profile-name text-center m-0">{name}</p>
      <p className="profile-title text-center m-0">{title}</p>
    </div>
  );
}

export default PersonOverview;
