import { Card, CardBody } from "reactstrap";
import { FaTiktok, FaFacebook, FaYoutube, FaXTwitter, FaInstagram } from "react-icons/fa6";

const SocialMediaMetric = ({source, metric, value, growth, description}) => {
  const icon = {
    instagram: <FaInstagram size={32} className="metric-icon" />,
    facebook: <FaFacebook size={32} className="metric-icon" />,
    youtube: <FaYoutube size={32} className="metric-icon" />,
    tiktok: <FaTiktok size={32} className="metric-icon" />,
    x: <FaXTwitter size={32} className="metric-icon" />,
  };

  const growthClass = parseFloat(growth) >= 0 ? 'positive' : 'negative';

  return (
    <Card className="social-metric">
      <CardBody>
        <div className="metric-content">
          <div className="metric-left">
            {icon[source]}
            <div>
              <p className="metric-title">{source}</p>
              <p className="metric-subtitle">{metric}</p>
              <p className="metric-subtitle">{description}</p>
            </div>
          </div>
          <div className="metric-right">
            <p className="metric-value">{value}</p>
            <p className={`metric-growth ${growthClass}`}>{growth}</p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default SocialMediaMetric;
