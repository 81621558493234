import React from 'react'
import { Card, CardBody, Spinner } from 'reactstrap';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import solidGauge from "highcharts/modules/solid-gauge";
import { usePerformanceIndex } from '../query/hooks';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

HighchartsMore(Highcharts);
solidGauge(Highcharts);

const PerformanceIndex = () => {
  const { t } = useTranslation();
  const { data, isLoading, isRefetching } = usePerformanceIndex();

  const options = {
    credits: { enabled: false },
    chart: {
      type: 'solidgauge',
      height: 150,
      backgroundColor: 'transparent',
    },
    title: null,
    pane: {
      center: ['50%', '85%'],
      size: '150%',
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: 'var(--performance-gauge-background)',
        borderRadius: 5,
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc',
        borderColor: 'transparent'
      }
    },

    exporting: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    yAxis: {
      stops: [
        [0.1, 'var(--performance-gauge-color)'],
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 0,
      labels: {
        y: 16,
        style: {
          color: 'var(--performance-text-primary)',
          fontSize: '16px',
        }
      },
      min: 0,
      max: 100,
    },

    series: [{
      name: 'Performance Index',
      data: [data?.performanceIndex],
      dataLabels: {
        format:
          '<div style="text-align:center">' +
          '<span style="font-size:24px; font-weight: normal; color: var(--performance-text-primary);">{y}</span><br/>' +
          '</div>',
        y: -40,
      },
    }],

    plotOptions: {
      solidgauge: {
        borderRadius: 3,
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true
        }
      }
    }
  };

  const statStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'var(--performance-text-primary)',
  };

  const dividerStyle = {
    borderColor: 'var(--performance-divider)',
    opacity: 0.2,
    margin: '0.75rem 0',
  };

  return (
    <Card className="performance-index-card">
      <CardBody>
        {(isLoading || isRefetching) && <Spinner color="primary"></Spinner>}
        <h2 className="card-title mb-4">{t('Performance Index')}</h2>
        <div className="mb-2">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
        <div className="mb-4" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ width: '100%', maxWidth: 400 }}>
            <div style={statStyle}>
              <span>{t('Total Followers')}</span>
              <span>{numeral(data?.totalFollowers ?? 0).format('0.0a')}</span>
            </div>
            <hr style={dividerStyle} />
            <div style={statStyle}>
              <span>{t('Profile Reach')}</span>
              <span>{((data?.profileReach ?? 0) * 100).toFixed(2)}%</span>
            </div>
            <hr style={dividerStyle} />
            <div style={statStyle}>
              <span>{t('Audience Growth Rate')}</span>
              <span>{((data?.audienceGrowthRate ?? 0) * 100).toFixed(2)}%</span>
            </div>
            <hr style={dividerStyle} />
            <div style={statStyle}>
              <span>{t('Post Frequency')}</span>
              <span>{Math.floor(data?.postFrequencyPerMonth ?? 0)} {t('per month')}</span>
            </div>
            <hr style={dividerStyle} />
            <div style={statStyle}>
              <span>{t('Engagement Rate')}</span>
              <span>{((data?.engagementRate ?? 0) * 100).toFixed(2)}%</span>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
};

export default PerformanceIndex;