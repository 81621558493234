import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";


/* Components */
import VisibilityGraph from "../components/VisibilityGraph";
import EngagementGraph from "../components/EngagementGraph";
import OverallMetric from "../components/OverallMetric";
import SoldTickets from "../components/SoldTickets";
import NextMatch from "../components/NextMatch";
import InfoTooltip from "../components/InfoTooltip";
import LineGraph from '../components/LineGraph'
import {
  useBrandSources,
  useEngagementChannels,
  useTvEngagement,
} from "../query/hooks";

TagManager.dataLayer({
  dataLayer: {
    event: "pageview",
    pagePath: "/dashboard",
    pageTitle: "Dashboard Eliteserien",
  },
});

const DashboardEliteserien = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="container-fluid pt-4 mt-3">
        <Row className="mb-4">
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              metric="engagement"
              followCalender
              tooltip={t(
                "Viser totalt engasjement som er skapt inklusive antall likes, kommentarer og delinger i sosiale kanaler, samt nettsidebesøk med mer enn to sidevisninger og antall personer som har sett hele kamper på TV. Prosent-tallet viser trendutvikling sammenlignet med forrige måned."
              )}
            />
          </Col>
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              metric="visibility"
              followCalender
              tooltip={t(
                "Viser totalt antall visninger (impressions) som er skapt på sosiale medier, nettsider og TV (antall som har sett minimum 1 minutt av kampene). Prosent-tallet viser trendutvikling sammenlignet med forrige måned. "
              )}
            />
          </Col>
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              metric="audience"
              tooltip={t(
                "Viser total verdi av alle visninger (impresssions) kalkulert med standard mediepriser for sosiale medier, nettsider og TV. Prosent-tallet viser trendutvikling sammenlignet med forrige måned."
              )}
              noTrend
              followCalender
            />
          </Col>
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              noTrend
              followCalender
              metric="tickets"
              tooltip={t(
                "Viser totalt antall enkeltbilletter som er solgt. Prosent-tallet viser trendutvikling sammenlignet med forrige måned."
              )}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="mb-4 mb-xl-0" sm={12} md={6}>
            <Row className="h-100">
              <Col className="mb-4 mb-xl-0" sm={12} md={6}>
                <NextMatch />
              </Col>
              <Col sm={12} md={6}>
                <Card className="mini-stat bg-card text-white h-100">
                  <CardBody>
                    <h4 className="card-title mb-4">
                      {t("Billettsalg")}
                      <InfoTooltip
                        tooltip={t(
                          "Viser sum av antall solgte sesongkort pluss enkeltbilletter som er solgt til neste hjemmekamp. Prosent-tallet viser fyllingsgraden dette gir ut fra stadion-kapasitet. Tallene oppdateres fortløpende. Tall hentet fra TicketCo via LimeCRM."
                        )}
                      />
                    </h4>
                    <SoldTickets />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Engasjement kanaler")}
                  <InfoTooltip
                    tooltip={t(
                      "Viser totalt antall følgere, engasjement (likes, delinger, kommentarer etc) og synlighet (visninger) som er skapt via klubbens kanaler, målt opp mot gjennomsnittet i ligaen."
                    )}
                  />
                </h4>
                <LineGraph
                  useGraphQuery={useEngagementChannels}
                  defaultActiveTab={0}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="mb-4 mb-xl-0" sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Engasjement")}
                  <InfoTooltip
                    tooltip={t(
                      "Viser utviklingen i engasjement (likes, delinger, kommentarer etc) per kanal måned for måned og akkumulert hittil i år."
                    )}
                  />
                </h4>
                <EngagementGraph />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Synlighet")}
                  <InfoTooltip
                    tooltip={t(
                      "Viser utviklingen i synlighet (visninger/impressions) per kanal måned for måned og akkumulert hittil i år."
                    )}
                  />
                </h4>
                <VisibilityGraph />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="mb-4 mb-xl-0" sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">{t("TV seere")}</h4>
                <LineGraph
                  defaultActiveTab={1}
                  useGraphQuery={useTvEngagement}
                />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">{t("Presseomtale")}</h4>
                <LineGraph useGraphQuery={useBrandSources} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  tenant: state.auth.tenant,
  date: state.date,
});

export default connect(mapStateToProps, {})(DashboardEliteserien)
