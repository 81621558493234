import { Card, CardBody } from "reactstrap";
import { FaTiktok, FaFacebook, FaYoutube, FaXTwitter, FaInstagram } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { BOXED_METRIC } from "../query/keys";
import { useSelector } from "react-redux";
import ApiService from "../services/ApiService";
import { format } from "date-fns";
import CardSpinner from "./CardSpinner";

const BoxedMetric = ({
    icon = null,
    metric,
    colour = "",
    link = false,
    titleFn = (primary, secondary) => '',
    primaryFn = (primary) => '',
    secondaryFn = (secondary) => '',
    enabled = false,
}) => {
  const tenant = useSelector((state) => state.auth.tenant);
  const date = useSelector((state) => state.date);
  const since = format(date.startDate, "yyyy-MM-dd");
  const until = format(date.endDate, "yyyy-MM-dd");

  const {
    data,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: [BOXED_METRIC, tenant.id, metric, since, until],
    queryFn: () => ApiService.getBoxedMetrics(metric, since, until),
    select: (data) => data.data,
    placeholderData: { data: {primary: null, secondary: null} },
  });

  const icons = {
    instagram: <FaInstagram size={32} className="metric-icon" />,
    facebook: <FaFacebook size={32} className="metric-icon" />,
    youtube: <FaYoutube size={32} className="metric-icon" />,
    tiktok: <FaTiktok size={32} className="metric-icon" />,
    x: <FaXTwitter size={32} className="metric-icon" />,
  };

  const primary = data?.primary;
  const secondary = data?.secondary ?? 0;

  // Get background gradient based on color prop
  const getBackgroundStyle = () => {
    switch (colour) {
      case 'red':
      case 'violet':
      case 'blue':
      case 'greyblue':
      case 'yellow':
        return { background: `var(--metric-${colour}-gradient)`, color: 'var(--metric-secondary-text)' };
      case 'dark':
      default:
        return { background: 'var(--metric-theme)', color: 'var(--metric-text)' };
    }
  };

  const content = (
    <>
      <div className="d-flex align-items-center justify-content-center">
        {icon ? icons[icon] : <div className="metric-title">{titleFn(primary, secondary)}</div>}
      </div>
      <div className="metric-value">{primaryFn(primary)}</div>
      <div className="metric-secondary">{secondaryFn(secondary)}</div>
    </>
  );

  return (

      <div className="boxed-metric" style={getBackgroundStyle()}>
        <Card>
          <CardBody className="text-center">
              <CardSpinner loading={isLoading || isRefetching} />
              {link ? <Link to={link} style={{textDecoration: 'none'}}><>{content}</></Link> : <>{content}</>}
          </CardBody>
        </Card>
      </div>
  );
}

export default BoxedMetric;