import React, { useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import SponsorSourceDividedValueChart from "../components/SponsorSourceDividedValueChart";
import OverallMetric from "../components/OverallMetric";
import SponsorObjectTabs from "../components/SponsorObjectTabs";
import SponsorLogoStatsChart from "../components/SponsorLogoStatsChart";
import { connect } from "react-redux";
import LineGraph from "../components/LineGraph";
import {
  useSponsorReach,
  useSponsorValue,
  useSponsorDividedReach,
  useSponsorDividedValue,
  useSponsorStationDividedValue,
  /* useSponsorValueDividedYear, */
} from "../query/hooks";
import BarGraph from "../components/BarGraph";
import useSponsorTvDiveded from "../query/hooks/useSponsorTvDiveded";
/* import SummaryDonutChart from "../components/SummaryDonutChart"; */

const DashboardSponsor = ({ selectedCurrency, tenant }) => {
  const { t } = useTranslation();
  const [params, setParams] = useState({ sponsorObjectId: 0 });

  const onClickTab = (tabIndex, newSponsorObjectId) => {
    setParams((prev) => ({ ...prev, sponsorObjectId: newSponsorObjectId }));
  };
  var hideTabs = false;
  if (tenant.name === "Bama") {
    hideTabs = true;
  }
  return (
    <>
      <div className="container-fluid pt- mt-3">
        {hideTabs ? null : <SponsorObjectTabs onClickTab={onClickTab} />}
        <Row className="mb-4">
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              followCalender
              isCurrency
              metric="sponsor-value"
              params={params}
              append=""
            />
          </Col>
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              followCalender
              metric="sponsor-exposures"
              params={params}
            />
          </Col>
          <Col xl={3} md={6}>
            <OverallMetric
              followCalender
              metric="sponsor-reach"
              params={params}
              append=""
            />
          </Col>
          <Col xl={3} md={6}>
            <OverallMetric
              followCalender
              metric="sponsor-time"
              params={params}
              append="min"
            />
          </Col>
        </Row>
        {/* <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white" style={{minHeight: 400}}>
              <CardBody>
                <h4 className="card-title mb-4">{t("Value")}</h4>
                <SummaryDonutChart useQuery={useSponsorValueDividedYear} />
              </CardBody>
            </Card>
          </Col>
        </Row> */}
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">{t("VISNINGER")}</h4>
                <LineGraph
                  useGraphQuery={useSponsorReach}
                  queryParams={[params.sponsorObjectId]}
                  defaultActiveTab={0}
                />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Sponsorverdi")} ({selectedCurrency.currency})
                </h4>
                <LineGraph
                  useGraphQuery={useSponsorValue}
                  queryParams={[params.sponsorObjectId]}
                  defaultActiveTab={0}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">{t("Visninger per kanal")}</h4>
                <LineGraph
                  useGraphQuery={useSponsorDividedReach}
                  queryParams={[params.sponsorObjectId]}
                  defaultActiveTab={0}
                />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Sponsorverdi per kanal")} ({selectedCurrency.currency})
                </h4>
                <LineGraph
                  useGraphQuery={useSponsorDividedValue}
                  queryParams={[params.sponsorObjectId]}
                  defaultActiveTab={0}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Press and TV should not be a part of this. Removing it since most clubs dont have data for instagram/facebook */}
        {/* <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">{t("Engasjement per kanal")}</h4>
                <LineGraph
                  useGraphQuery={useSponsorDividedEngagement}
                  queryParams={[params.sponsorObjectId]}
                  defaultActiveTab={0}
                />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Engasjementsverdi per kanal")} ({selectedCurrency.currency})
                </h4>
                <LineGraph
                  useGraphQuery={useSponsorDividedEngagementValue}
                  queryParams={[params.sponsorObjectId]}
                  defaultActiveTab={0}
                />
              </CardBody>
            </Card>
          </Col>
        </Row> */}

        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Sponsorverdi per TV-kanal")} ({selectedCurrency.currency})
                </h4>
                <LineGraph
                  useGraphQuery={useSponsorStationDividedValue}
                  queryParams={[params.sponsorObjectId]}
                  defaultActiveTab={0}
                />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Sponsorverdi per medie")} ({selectedCurrency.currency})
                </h4>
                <SponsorSourceDividedValueChart
                  sponsorObjectId={params.sponsorObjectId}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">{t("Eksponeringer")}</h4>
                <SponsorLogoStatsChart
                  sponsorObjectId={params.sponsorObjectId}
                />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">{t("TV seere per oppgjør")}</h4>
                <BarGraph useGraphQuery={useSponsorTvDiveded} queryParams={[params.sponsorObjectId]} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  selectedCurrency: state.currency.selectedCurrency,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(DashboardSponsor);
