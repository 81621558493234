import React, { createContext, useContext, useEffect } from 'react';
import useDarkMode from 'use-dark-mode';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const darkMode = useDarkMode(false, {
    storageKey: 'foocus-theme-mode',
    onChange: (isDark) => {
      document.documentElement.setAttribute('data-theme', isDark ? 'dark' : 'light');
    }
  });

  useEffect(() => {
    // Set initial theme
    document.documentElement.setAttribute('data-theme', darkMode.value ? 'dark' : 'light');
  }, []);

  const value = {
    isDark: darkMode.value,
    toggle: darkMode.toggle,
    enable: darkMode.enable,
    disable: darkMode.disable
  };

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }
  return context;
};
