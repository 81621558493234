import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { PERFORMANCE_INDEX } from "../keys";

const usePerformanceIndex = () => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [PERFORMANCE_INDEX, tenant.id],
    queryFn: ApiService.getPerformanceIndex,
    select: (data) => {
      return {
        ...data.data,
        performanceIndex: Math.round(data.data.performanceIndex),
      };
    },
    placeholderData: {
      totalFollowers: 0,
      profileReach: 0,
      audienceGrowthRate: 0,
      postFrequencyPerMonth: 0,
      engagementRate: 0,
      performanceIndex: 0,
    },
  });
};

export default usePerformanceIndex;
