import React from "react";
import { useThemeContext } from "../contexts/ThemeContext";
import moon from '../assets/moon-icon.png';
import sun from '../assets/sun-icon.png';

const ThemeBtn = () => {
  const { isDark, toggle } = useThemeContext();

  return (
    <button 
      className="bg-transparent border-0 theme-toggle-btn"
      onClick={toggle}
      aria-label={isDark ? "Switch to light mode" : "Switch to dark mode"}
    >
      <img 
        src={isDark ? sun : moon} 
        alt={isDark ? "Sun icon" : "Moon icon"} 
        className={isDark ? "theme-icon sun" : "theme-icon moon"}
      />
    </button>
  );
};

export default ThemeBtn;