import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Nav, NavItem, NavLink, Spinner } from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { usePartnerValuePerSource } from "../query/hooks";
import { useTheme } from "../utils/useTheme";

const SourceDividedValueChart = ({ selectedCurrency, partnerId, selectedTenantId }) => {
  const [activeTab, setActiveTab] = useState(0);
  var darkTheme = true
  // check if light or dark mode
  if (useTheme() === "light-mode"){
    darkTheme = false
  }

  const {
    data: sources,
    isLoading,
    isRefetching,
    isSuccess,
  } = usePartnerValuePerSource(partnerId, selectedTenantId);

  const chartOptions = (source) => {
    var labelcolor = '#505050'
    if (darkTheme === true) {
      labelcolor = '#fff'
    }
    return {
      credits: {
        enabled: false
      },
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: source.data.yTitle,
        },
        labels: {
          style: {
            color: labelcolor
          }
        },
      },
      xAxis: {
        type: 'category',
        min: 0,
        labels: {
          animate: true,
          style: {
            color: labelcolor
          }
        }
      },
      series: source.data.series.map((serie) => ({
        ...serie,
        data: serie.data,
        dataSorting: {
          enabled: true,
        },
        borderColor: null,

      })),
      plotOptions: {
        column: {
          stacking: "normal",
        },
      },
    };
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const renderTab = (graph, index) => {
    return (
      <NavItem key={index}>
        <NavLink
          href="#"
          className={classnames({ active: activeTab === index })}
          onClick={() => toggleTab(index)}
        >
          {graph.source}
        </NavLink>
      </NavItem>
    );
  };

  const renderSource = (graph, index) => {
    const chart = JSON.parse(JSON.stringify(chartOptions(graph)));
    return <HighchartsReact highcharts={Highcharts} options={chart} />;
  };

  return (
    <>
      {(isLoading || isRefetching) && <Spinner color="primary" />}
      {isSuccess && (
        <>
          <Nav pills className="navtab-bg my-4 center-pills">
            {sources.map((source, index) => renderTab(source, index))}
          </Nav>
          {sources[activeTab] && renderSource(sources[activeTab])}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedCurrency: state.currency.selectedCurrency,
});

export default connect(mapStateToProps)(SourceDividedValueChart);
